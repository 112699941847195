import { useStaticQuery, graphql } from 'gatsby';

const infoChoice = (data: Array<any> = [], count = 3, skip = 0) => {
  const ary = data.map((item) => {
    const data = item
    const basepath = "/news/"
    const isPdf = data.node.pdf?.url
    data.node.isBlank = data.node.link?.blank || isPdf ? true : false;
    data.node.hrefstr = data.node.pdf?.url || data.node.link?.href || basepath + data.node.infoKyoOndokoroId + "/";
    return data
  })
  return ary.slice(skip, count == 0 ? undefined : count)
}

export default infoChoice