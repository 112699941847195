import React, { useEffect, useState } from "react"
import { graphql, Link } from 'gatsby';
import Slider from "react-slick";
import {
  Layout,
  SEO,
  CNewsList,
  Search,
  wovnCheck,
} from '../components/_index';
import "slick-carousel/slick/slick.css";
import infoChoice from '../utils/info-choice';
import infoGet from '../utils/info-get';

// markup
const IndexPage = () => {
  const [movieId, setMovieId] = useState('WEwUdsWvZBk')
  wovnCheck(function (wovnCode: string) {
    const movie = wovnCode !== 'ja' ? '74Jt-NpIP5Y' : 'WEwUdsWvZBk';
    setMovieId(movie)
  });

  return (
    <Layout>
      <SEO ogtype={'website'} />
      <main id="top" className="">
        <div id="topMvSlideWrap">
          <div id="topMvSlide">
            <div className="slider">
              <Slider {...{
                dots: false,
                arrows: false,
                autoplay: true,
                autoplaySpeed: 5000,
                speed: 1500,
                fade: true,
                pauseOnFocus: false,
                pauseOnHover: false,
              }}>
                <div className="slideBox">
                  <Link to="/facility/kamanza-nijo/">
                    <img src="/images/top/kamanzaMv/top_kamanza_mv01.jpg" style={{ objectPosition: '50% 50%' }} alt="釜座二条" />
                    <div className="txtBox"><p>釜座二条<span>KAMANZA-NIJO　ちいさな「住まい」</span><i># 2</i></p></div>
                  </Link>
                </div>
                <div className="slideBox">
                  <Link to="/facility/gokomachi-ebisugawa/">
                    <img src="/images/top/gokomachiMv/top_gokomachi_mv01.jpg" alt="竹屋町 料理" />
                    <div className="txtBox"><p>御幸町夷川<span>GOKOMACH-EBISUGAWA　茶道の香り</span><i># 3</i></p></div>
                  </Link>
                </div>
                <div className="slideBox">
                  <Link to="/facility/fuyacho-nijo/">
                    <img src="/images/top/fuyachoMv/top_fuyacho_mv01.jpg" alt="麩屋町二条" />
                    <div className="txtBox"><p>麩屋町二条<span>FUYACHO-NIJO　伝統文化が息づく宿</span><i># 4</i></p></div>
                  </Link>
                </div>
                <div className="slideBox">
                  <Link to="/facility/nishijin-villa/">
                    <img src="/images/top/nishijinMv/top_nishijin_mv01_2.jpg" alt="西陣別邸" />
                    <div className="txtBox"><p>西陣別邸<span>NISHIJIN-VILLA　京の別邸</span><i># 5</i></p></div>
                  </Link>
                </div>
                <div className="slideBox">
                  <Link to="/facility/goshonishi/">
                    <img src="/images/top/goshonishiMv/top_goshonishi_mv01_1.jpg" alt="御所西" />
                    <div className="txtBox"><p>御所西<span>GOSHONISHI　季節を豊かに感じる京町家</span><i># 6</i></p></div>
                  </Link>
                </div>
                <div className="slideBox">
                  <Link to="/facility/marutamachi/">
                    <img src="/images/top/marutamachiMv/top_marutamachi_mv01.jpg" alt="丸太町" />
                    <div className="txtBox"><p>丸太町<span>MARUTAMACHI　美とアート 女子旅の宿</span><i># 7</i></p></div>
                  </Link>
                </div>
                <div className="slideBox">
                  <Link to="/facility/takeyamachi/">
                    <img src="/images/top/takeyamachiMv/top_takeyamachi_mv01_1.jpg" alt="竹屋町" />
                    <div className="txtBox"><p>竹屋町<span>TAKEYAMACHI　「職住一体」の京町家</span><i># 8</i></p></div>
                  </Link>
                </div>
              </Slider>
            </div>
          </div>
        </div>
        <div className="topSearchArea">
          <Search formId="toppage" />
        </div>
        <div id="topLead">
          <br />・・・<br /><br />
          <div className="sent">
            <p>ひとつの旅が<br className="sp" />京都のより良い未来を紡いていく</p>
            <p>消えゆく京町家を次世代へつなぎたい<br />そんな想いからスタートした『京の温所』<br />人々の価値観や旅のあり方も変化しはじめた今<br />私たちが営みを通じて、地元京都に出来ること</p>
            <p>築100年以上の京町家一棟貸しの宿で<br />日常のように過ごす<br /></p>
            <p>この街で愛され続けた味を食す<br />京都ならではの地域文化について学ぶ<br />そんな旅の１コマが地域社会の循環につながっていきます
              <br /><br />・・・<br /></p>
          </div>
          <p id="topLeadCopy"><strong>『京の温所』が目指す未来のまちづくりにつながる新しい旅 </strong></p>
        </div>
        <section id="topNews" className="">
          <div className="img">
            <img className="img01" src="images/top/top_img01_01.jpg" alt="" />
          </div>
          <div className="secIn">
            <h2 className="tit"><span wovn-ignore="true">NEWS</span><span className="sub">京の温所からのお知らせ</span></h2>
            <CNewsList data={infoChoice(infoGet(), 3)} />
            <div className="btnType02 btn"><a href="news/">お知らせ一覧</a></div>
          </div>
        </section>
        <section id="topProduce">
          <div className="secIn">
            <h2 className="tit"><span wovn-ignore="true">PRODUCE</span><span className="sub">プロデュース</span></h2>
            <div className="cont">
              <div className="img"><img src="images/top/top_produceImg01.png" width="540" height="162" alt="" /></div>
              <ul className="produceArea">
                <li>
                  <div className="cont">
                    <div className="produceTitArea">
                      <h3 className="produceTit"><a href="https://www.wacoalholdings.jp/" target="_blank">株式会社ワコール</a></h3>
                      <p className="job">プロデュース</p>
                    </div>
                    <p className="sent">創業時の地・京都の美しい街並みを後世へと引き継ぐため、京町家に“心安らぐ宿”という、新しい命を吹き込みます。<br />訪れる人々の豊かな時が、これからも、脈々と刻まれますように。</p>
                  </div>
                </li>
                <li>
                  <div className="cont">
                    <div className="produceTitArea">
                      <h3 className="produceTit"><a href="https://www.spiral.co.jp/" target="_blank">スパイラル / 株式会社ワコールアートセンター</a></h3>
                      <p className="job">ディレクション</p>
                    </div>
                    <p className="sent">1985年創設以来、文化の事業化を謳い「生活とアートの融合」をコンセプトとして活動しているスパイラル。クリエイティブネットワークを活用し、アーティストと協同しながらアートの実社会への応用を目指しています。</p>
                  </div>
                </li>
              </ul>
            </div>
            <div id="topScheme" className="ja_topScheme">
              <h3 className="tit">「京の温所」が目指すこと</h3>
              <p className="sent">―「食べる・学ぶ・遊ぶ・集う」―旅の１コマ</p>
              <p className="sent">京都を創業の地とする株式会社ワコールは、<br className="sp" />「京都の街並みを次世代に残したい」<br />という強い思いから、<br className="sp" />京町家保全・活用することによる<br className="sp" />地域共生を目的とした<br />社会課題解決事業を2018年度にスタートしました。<br />住み手がなく手付かずとなった町家を、<br className="sp" />10年～15年でお借りし、<br />上質なリノベーションを行うことで<br className="sp" />宿泊施設として運営し<br />活用後そのままの状態でお返ししています。<br />宿にお泊りいただくだけでなく、<br />地元の飲食店や惣菜店・京都ガイドなどと<br className="sp" />連携しながら、<br />京都の魅力ある資源を守り、<br className="sp" />次世代につないでいくことで<br />「共に創る京都」を目指しています。</p>
            </div>
          </div>
        </section>
        <section id="topFacilities">
          <div id="frontMap" className="map">
            <div id="jaMap" className="map"><div className="iframe-wrap"><iframe src="https://www.google.com/maps/d/embed?mid=1Rc_hsag2ZR38zUsX3ExlEVxG32ql0e67" ></iframe></div></div></div>     <div className="cont">
            <div className="btnType02 btn"><Link to="/facility/">施設一覧</Link>
            </div>
          </div>
        </section>
        <section id="topMovies">
          <div className="c_youtube">
            <div className="inner">
              <iframe src={`https://www.youtube.com/embed/${movieId}`} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen={true} loading="lazy" />
            </div>
          </div>
        </section>
        <section id="topStay">
          <div id="stayWrap" className="wd">
            <div id="stayAbout" className="">
              <h3 className="lead">
                「京の温所」には、いわゆるホテルや<br className="sp" />旅館のようなサービスはありません。<br />でも、食べる・学ぶ・遊ぶ・集う・暮らす<br />温かく心安らぐ豊かな時間を<br className="sp" />過ごしていただくための<br className="sp" />ヒントをそろえました。</h3>
            </div>
            <section className="staySec">
              <h4 className="staySecTit">「食べる」</h4>
              <div className="img"><img src="/images/stay/stay_taberuImg.jpg" alt="" /></div>
              <div className="staySecTxts">
                <p className="staySecTxt">宿には調理器具や食器類を<br className="sp" />一通り揃えています。<br />ご滞在される皆さまで<br className="sp" />温かなお料理をつくり味わう喜び。</p>
              </div>
            </section>
            <section className="staySec">
              <h4 className="staySecTit">「学ぶ」</h4>
              <div className="img"><img src="/images/stay/stay_manabuImg.jpg" alt="" /></div>
              <div className="staySecTxts">
                <p className="staySecTxt">全ての棟には本をご用意しています。<br />ふと手にとった本の一節が心に留まり、<br className="sp" />人生の指南となることもあるでしょう。</p>
              </div>
            </section>
            <section className="staySec">
              <h4 className="staySecTit">「遊ぶ」</h4>
              <div className="img"><img src="/images/stay/stay_asobuImg.jpg" alt="" /></div>
              <div className="staySecTxts">
                <p className="staySecTxt">作法を気にせず、<br className="sp" />縁側でお抹茶を点ててゆったりと過ごす。<br />時間をかけて丁寧に珈琲を淹れる。<br />ゆっくりと自分を愛しむ<br className="sp" />スキンケアを楽しんでみる。<br />あえて何も生けていない花器には、<br />近所で買った一輪の花を挿して自分だけの空間に。<br />各施設には必ずアート作品が飾られており、<br className="sp" />じっくりとご鑑賞いただけます。<br />空間を存分にお楽しみいただくために、<br className="sp" />あえてテレビは置いていません。<br /><small>（※一部の棟にはポータブルタイプを設置しています）</small><br />車に乗れば、<br className="sp" />豊かな自然あふれる美山や大原もすぐそこ。<br />自分だけの遊び方を見つけてください。</p>
              </div>
            </section>
            <section className="staySec">
              <h4 className="staySecTit">「集う」</h4>
              <div className="img"><img src="/images/stay/stay_tudouImg.jpg" alt="" /></div>
              <div className="staySecTxts">
                <p className="staySecTxt">少人数でのご滞在はもちろん、広い町家では、<br className="sp" />最大８名様までご宿泊いただけます。<br />各施設には全員で食事を摂れる<br className="sp" />ダイニングをはじめ、<br />掘りごたつのある和室やラウンジスペースなど<br />ご友人やご家族同士で<br />それぞれの間取りに合わせた空間で<br className="sp" />ゆったりとお寛ぎください。</p>
              </div>
            </section>
            <section className="staySec">
              <h4 className="staySecTit">「暮らす」</h4>
              <div className="img"><img src="/images/stay/stay_sumuImg.jpg" alt="" /></div>
              <div className="staySecTxts">
                <p className="staySecTxt">オーナー様にお返しした後も<br />「住まい」として継いでいただくことを<br className="sp" />前提にリノベーションをしています。<br />それぞれの町家の個性を大切にしながら、<br />家具・床材・浴槽の形までも<br className="sp" />各施設で変えるほどにこだわっています。</p>
                <p className="staySecTxt">京都にあるもうひとつの「おうち」として<br />ここちよい暮らしをお楽しみください。</p>
              </div>
            </section>
          </div>
        </section>

      </main>
    </Layout>
  );
};

export default IndexPage;
